export default function tabs() {
    const tabs = document.querySelectorAll('[role="tab"]');
    const tabList = document.querySelector('[role="tablist"]');

    // Add a click event handler to each tab
    tabs.forEach(tab => {
        tab.addEventListener("click", changeTabs);
    });

    //Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;
    if (tabList) {
        tabList.addEventListener("keydown", e => {
            // Move right
            if (e.keyCode === 39 || e.keyCode === 37) {
                tabs[tabFocus].setAttribute("tabindex", -1);
                if (e.keyCode === 39) {
                    tabFocus++;
                    // If we're at the end, go to the start
                    if (tabFocus >= tabs.length) {
                        tabFocus = 0;
                    }
                    // Move left
                } else if (e.keyCode === 37) {
                    tabFocus--;
                    // If we're at the start, move to the end
                    if (tabFocus < 0) {
                        tabFocus = tabs.length - 1;
                    }
                }

                tabs[tabFocus].setAttribute("tabindex", 0);
                tabs[tabFocus].focus();
            }
        });
    }


}

function changeTabs(e) {
    const target = e.target;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;

    // Remove all current selected tabs
    parent
        .querySelectorAll('[aria-selected="true"]')
        .forEach(t => {
            t.setAttribute("aria-selected", false);
            t.classList.remove('border-b-2')
            t.classList.remove('border-blue-700')
            t.classList.remove('text-blue-700')
            t.classList.add('text-gray-600')
            t.classList.add('hover:text-gray-800')
            t.classList.remove('cursor-default')

        });

    // Set this tab as selected
    target.setAttribute("aria-selected", true);
    target.classList.add('border-b-2')
    target.classList.add('border-blue-700')
    target.classList.add('text-blue-700')
    target.classList.remove('text-gray-600')
    target.classList.remove('hover:text-gray-800')
    target.classList.add('cursor-default')

    // Hide all tab panels
    grandparent
        .querySelectorAll('[role="tabpanel"]')
        .forEach(p => p.setAttribute("hidden", true));

    // Show the selected panel
    grandparent.parentNode
        .querySelector(`#${target.getAttribute("aria-controls")}`)
        .removeAttribute("hidden");
}
