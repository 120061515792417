export default function filters() {
    const filters = document.querySelectorAll('.selected');

    if (filters) {

        filters.forEach((filter, index, filters) => {
            filter.addEventListener('click', function () {
                if (!filter.parentNode.classList.contains('select-link--expanded')) {

                    this.parentNode.classList.add('select-link--expanded');
                    filters.forEach(element => {
                        element.parentNode.classList.remove('select-link--expanded');
                    });

                } else {

                    this.parentNode.classList.remove('select-link--expanded');
                }


            })
        })
    }

}