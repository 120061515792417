var app = {
    init: function () {

        this.caricapromo();
        this.promoTabs();
        this.changeImage();
        // this.tabs();
        this.mobileMenu();
        this.mobileDD();
        this.formTab();
        //this.closelistcheckout();
        this.servicesTooltip();
        this.tabScroll();
        this.modal();
        this.minWindowHwight();
        this.closeAlert();
        this.hideBanner();
        // this.accordion();
        this.hasTooltip();
        this.selectLink();
        // var spotlink = $.cookie("spotlink");
        // if (!spotlink) {

        //     $.cookie("spotlink", true, {
        //         expires: 7
        //     });
        //     $("#spotlink").show();
        // } else {


        // };

        this.readMoreButton();
        this.hideBannerVideoOnMobile();
        this.uploadFile();

        const carousels = document.querySelectorAll('.owl-carousel');

        if (carousels.length > 0) {

            $('.correlate-carousel').owlCarousel({
                loop: true,
                margin: 10,
                nav: false,
                dots: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 3
                    },
                    1000: {
                        items: 3,
                        loop: false
                    }
                }
            });

            var homeSlider = $('.owl-home-slider').owlCarousel({
                dots: false,
                items: 1,
                nav: false,
                loop: true

            });
            $('#slider-prev').click(function () {
                homeSlider.trigger('prev.owl.carousel');
            });
            $('#slider-next').click(function () {
                homeSlider.trigger('next.owl.carousel');

            });

            $('.promo-carousel-inner ').owlCarousel({
                dots: true,
                items: 1,
                responsive: {
                    480: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    }
                }

            });
            $('.brands-carousel ').owlCarousel({
                dots: true,
                loop: true,
                autoplay: true,
                autoplayTimeout: 1500,
                autoplayHoverPause: true,
                responsive: {
                    480: {
                        items: 1
                    },
                    768: {
                        items: 4
                    },
                    1024: {
                        items: 6
                    }
                }

            });
            $('.carosello-liste').owlCarousel({
                dots: true,
                nav: true,
                loop: false,
                items: 1,
                responsive: {
                    400: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 2
                    }
                }

            });
            $('.timeline-carousel').owlCarousel({
                // center: true,
                nav: true,
                loop: false,
                items: 1,
                responsive: {
                    400: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 2
                    }
                }

            });


        }


    },
    caricapromo: function () {

        var promos = $('.promo-offerte-list-inner'),
            promo = promos.find('li');

        // $(document).pjax(".promo-offerte-list-inner a", '#variable-content', {
        //     fragment: "#variable-content",
        //     scrollTo: false
        // });
        promo.click(function () {
            promo.removeClass('promo-offerta--current');
            $(this).addClass('promo-offerta--current');
            $("#offerta_id").val($(this).attr("id"));
            //$("#promo_prezzo").html($(this).find(".prezzo").html());
            //alert($(this).find(".prezzo").html());


        })
        // $(document).on('pjax:complete', function () {
        //     $('.correlate-carousel').owlCarousel({
        //         loop: true,
        //         margin: 10,
        //         nav: false,
        //         dots: true,
        //         responsive: {
        //             0: {
        //                 items: 1
        //             },
        //             600: {
        //                 items: 3
        //             },
        //             1000: {
        //                 items: 3,
        //                 loop: false
        //             }
        //         }
        //     });

        // })

    },

    promoTabs: function () {
        var tabs = $('.promo-tabs'),
            tab = tabs.find('.promo-tab'),
            nextTab = tabs.find('#nextTab'),
            prevTab = tabs.find('#prevTab'),
            tabsContainer = tabs.find('.promo-tabs-container'),
            tabsWrapper = tabs.find('.promo-tabs-inner'),

            n = 0;

        tab.click(function () {
            tab.removeClass('is-active');
            $(this).addClass('is-active');

        })

        // ---------------------------------------
        // Mobile
        // --------------------------------------- 
        if (this.isMobile()) {
            if ($(".tab-card--current").length) {
                var off = $(".tab-card--current").offset();
                $(".tab-cards__container").animate({ scrollLeft: off.left - 16 }, 1);
            }
            if (tab.length <= 1) {
                tab.css('width', tabsContainer.width() + 'px');
                tabsWrapper.css('width', tabsContainer.width() * tab.length + 'px');
            } else if (tab.length > 1) {
                tab.css('width', tabsContainer.width() + 'px');
                tabsWrapper.css('width', tabsContainer.width() * tab.length + 'px');
                nextTab.removeClass('disabled');
                n = tab.length - 1;
            }
            nextTab.click(function () {
                if (n >= 1) {
                    tabsWrapper.animate({
                        left: '-=' + tabsContainer.width() + 'px',
                    })
                    prevTab.removeClass('disabled');
                    n--
                    if (n < 1) {
                        nextTab.addClass('disabled');

                    }
                }
            });
            prevTab.click(function () {
                if (n < tab.length - 1) {
                    tabsWrapper.animate({
                        left: '+=' + tabsContainer.width() + 'px',
                    })
                    nextTab.removeClass('disabled');
                    n++
                    if (n >= tab.length - 1) {
                        prevTab.addClass('disabled');
                    }
                }
            });

        }

        // ---------------------------------------
        // tablet
        // --------------------------------------- 
        if (this.isTablet()) {
            if ($(".tab-card--current").length) {
                var off = $(".tab-card--current").offset();
                $(".tab-cards__container").animate({ scrollLeft: off.left - 16 }, 1);
            }
            tab.css('width', (tabsContainer.width() / 2) - 8 + 'px');
            tabsWrapper.css('width', (tabsContainer.width() / 2) * tab.length + 'px');


            if (tab.length <= 2) {
                tab.css('width', (tabsContainer.width() / 2) - 8 + 'px');
                tabsWrapper.css('width', (tabsContainer.width() / 2) * tab.length + 'px');
            } else if (tab.length > 1) {
                tab.css('width', (tabsContainer.width() / 2) - 8 + 'px');
                tabsWrapper.css('width', (tabsContainer.width() / 2) * tab.length + 'px');
                nextTab.removeClass('disabled');
                n = tab.length - 2;
            }
            nextTab.click(function () {
                if (n >= 2) {
                    tabsWrapper.animate({
                        left: '-=' + (tabsContainer.width() / 2) + 'px',
                    })
                    prevTab.removeClass('disabled');
                    n--
                    if (n < 2) {
                        nextTab.addClass('disabled');

                    }
                }
            });
            prevTab.click(function () {
                if (n < tab.length - 2) {
                    tabsWrapper.animate({
                        left: '+=' + (tabsContainer.width() / 2) + 'px',
                    })
                    nextTab.removeClass('disabled');
                    n++
                    if (n >= tab.length - 2) {
                        prevTab.addClass('disabled');
                    }
                }
            });

        }

        // ---------------------------------------
        // Desktop
        // --------------------------------------- 
        if (this.isDesktop()) {
            if (tab.length <= 3) {
                tab.css('width', (tabsContainer.width() / 3) - 8 + 'px');
                tabsWrapper.css('width', tabsContainer.width() + 'px');
            } else if (tab.length > 3) {
                tab.css('width', (tabsContainer.width() / 3) - 8 + 'px');
                tabsWrapper.css('width', (tabsContainer.width() / 3) * tab.length + 'px');
                nextTab.removeClass('disabled');
                n = tab.length - 3;
            }

            nextTab.click(function () {
                if (n >= 1) {
                    tabsWrapper.animate({
                        left: '-=' + (tabsContainer.width() / 3) + 'px',
                    })
                    prevTab.removeClass('disabled');
                    n--
                    if (n < 1) {
                        nextTab.addClass('disabled');

                    }
                }
            });
            prevTab.click(function () {
                if (n < tab.length - 3) {
                    tabsWrapper.animate({
                        left: '+=' + (tabsContainer.width() / 3) + 'px',
                    })
                    nextTab.removeClass('disabled');
                    n++
                    if (n >= tab.length - 3) {
                        prevTab.addClass('disabled');
                    }
                }
            });
        }
    },
    changeImage: function () {
        var controlls = $('.show-image-controlls'),
            controll = controlls.find('span'),
            image = $('.promo-image').find('img');

        controll.click(function () {
            controll.removeClass('current-image');
            $(this).addClass('current-image');
            image.removeClass('show-image');
            image.eq($(this).index()).addClass('show-image');

        });
    },
    tabs: function () {
        var tab = $('.tabs').find('.tab');
        var tabContent = $('.tab-content');

        tab.click(function () {
            tab.removeClass('is-active');
            $(this).addClass('is-active');
            tabContent.removeClass('current-tab');
            tabContent.eq($(this).index()).addClass('current-tab');
        });
    },
    mobileMenu: function () {
        var trigger = $('#mobile-menu-trigger');
        var hasSubMenu = $('.has-sub-menu');
        var sub = $('.sub-menu');
        var back = $('.back');
        var banner = document.getElementById('spotlink');
        var distance = (banner) ? banner.offsetHeight : null;
        let header = document.querySelector('.header');



        trigger.click(function () {
            $(this).toggleClass('is-opened');
            $('.mobile-menu').toggleClass('is-opened');

            if (window.pageYOffset < distance) {
                window.scrollBy(0, distance);
            }
            if ($('.mobile-menu').hasClass('is-opened')) {
                $('body').css('overflow-y', 'hidden');


            } else {
                $('body').css('overflow-y', 'auto');
            }
        });

        hasSubMenu.click(function (e) {
            e.preventDefault();
            sub.eq($(this).data('menu') - 1).addClass('is-showing');
        });

        back.click(function () {
            sub.eq($(this).data('menu') - 1).removeClass('is-showing');
        });





    },
    mobileDD: function (e) {
        var dd = $('.mobile-menu-item-has-dd');

        dd.click(function (e) {
            e.preventDefault();
            var el = $(this).find('ul.mobile-menu-dd li');
            var wrap = $(this).find('ul.mobile-menu-dd');

            $(this).toggleClass('dd-is-open');

            console.log(el.height());

            if (wrap.height() == 0) {
                wrap.css('height', (el.height() * el.length) + 'px');
            } else {
                wrap.css('height', '0px');
            }
        });

        $('.mobile-menu-dd').click(function (e) {
            e.stopPropagation();

        })
    },
    formTab: function () {
        var tab = $('.form-tabs').find('.form-tab');
        var form = $('.carrello-form').find('form');

        tab.click(function () {
            tab.removeClass('is-active');
            $(this).addClass('is-active');
            form.removeClass('is-active');

            form.eq($(this).index()).addClass('is-active');

        });


    },
    closelistcheckout: function () {
        var title = $('#riepilogo-titolo');
        var listBox = $('.carrello-items ');
        var height = (listBox.find('li.carrello-item').height() * listBox.find('.carrello-item').length) + 250;

        if (this.isMobile()) {

            if (listBox.find('.carrello-item').length > 0) {
                listBox.addClass('is-closed');
                title.addClass('is-clickable');
            }
            title.click(function () {
                if (listBox.hasClass('is-closed')) {
                    listBox.removeClass('is-closed');
                    if (listBox.find('.carrello-item').length > 0) {
                        listBox.css('height', height + 'px');
                    } else {
                        listBox.css('height', '250px');
                        listBox.addClass('is-closed');
                    }

                } else {
                    listBox.addClass('is-closed');
                    listBox.css('height', 0 + 'px');
                }


            });

        }


    },
    servicesTooltip: function () {
        var icon = $('.promo-services-2').find('li');

        if (this.isMobile() || this.isTablet()) {
            icon.click(function () {
                icon.removeClass('is-showing');
                $(this).addClass('is-showing');

                setTimeout(function () {
                    $(this).removeClass('is-showing');
                }, 1000);
            })
        }

        if (this.isDesktop()) {
            icon.on('mouseover', function () {
                icon.removeClass('is-showing');
                $(this).addClass('is-showing');

                setTimeout(function () {
                    $(this).removeClass('is-showing');
                }, 1000);
            })
        }



    },

    modal: function () {
        var modal = $('.modal');
        var modalBox = $('.modal').find('.modal-box');
        var closeModal = $('.modal').find('.close-modal');
        var openModal = $('.open-modal');

        modal.click(function () {
            $(this).removeClass('is-open');
        })
        openModal.click(function () {
            modal.addClass('is-open');
        })
        closeModal.click(function () {
            modal.removeClass('is-open');
            var videoURL = $('#spotvideo').prop('src');
            videoURL = videoURL.replace("&autoplay=1", "");
            $('#spotvideo').prop('src', '');
            $('#spotvideo').prop('src', videoURL);
        })

        modalBox.click(function (e) {
            e.stopPropagation();
        })
    },
    minWindowHwight: function () {

        $('main').css('min-height', $(window).height() - $('header').outerHeight() - $('footer').outerHeight() + 'px');
    },
    hideBanner: function () {
        var time = $('.banner').data('time-showing');

        if (time > 0) {

            setTimeout(function () {
                $('.banner').removeClass('is-showing');
            }, time * 1000);
        }

    },
    closeAlert: function () {
        $('.close-alert').click(function () {
            $('.alert').removeClass('is-open');
        })
    },
    // showVideo: function () {
    //     var video = $('#video-promo');
    //     if (this.isMobile()) {
    //         $('body').addClass('stop-scrolling');
    //         setTimeout(function () {
    //             $('.video-banner').css('height', '260px');
    //             $('.video-banner .video-wrapper').css({
    //                 'width': '100%',
    //                 'height': '100%'
    //
    //             });
    //             $('.site').css('margin-top', '210px');
    //             setTimeout(function () {
    //
    //                 video.css('opacity', 1);
    //
    //             }, 500);
    //
    //         }, 1000);
    //     } else {
    //         $('body').addClass('stop-scrolling');
    //         setTimeout(function () {
    //             $('.video-banner').css('height', $(window).height() + 'px');
    //             $('.site').css('margin-top', $(window).height() + 'px');
    //             setTimeout(function () {
    //
    //                 video.css('opacity', 1);
    //
    //
    //             }, 500);
    //
    //         }, 1000);
    //     }
    // },
    readMoreButton: function () {

        $('.read-more').each(function () {
            $(this).append('<span class="read-more__button" role="button">Leggi di più</span>');

            $('.read-more__button').click(function () {
                $(this).parent().toggleClass('read-more--expanded');

                if ($(this).text() === 'Leggi di più') {
                    $(this).text('Riduci')
                } else {
                    $(this).text('Leggi di più')
                }
            })

        })


    },
    hideBannerVideoOnMobile: function () {

        var device = this.isMobile();

        $(window).scroll(function () {
            //  if (device) {

            if ($(window).scrollTop() > 100) {
                $('#spotlink').fadeOut('slow');
            } else {
                //  $('#spotlink').css('display', 'block');
            }
            // }
        })

    },
    uploadFile: function () {

        var btn = $('.js-load-file');
        var input = $('.js-input-file');


        btn.click(function (e) {
            e.preventDefault();

            var index = $(this).data('click');
            $('#_' + index).click();


        });

        input.on('change', function () {
            var index = $(this).data('input');
            if ($(this).val()) {
                console.log();
                $('#msg_' + index).text($(this).val().replace("C:\\fakepath\\", ""));

                $('#btn_' + index).find('.text-button').text('Modifica');
                $('#icn_' + index).removeClass('fr-icon-upload').addClass('fr-icon-edit');



            } else {
                // $('#msg_' + index).html('Nessun file caricato');
                console.log(0);

            }
        })


    },
    tabScroll: function () {
        var tabCards = $('.tab-cards__container');
        var tabWidth = $('.tab-card').width();
        var next = $('#nextTab');
        var prev = $('#prevTab');

        next.click(function (e) {

            tabCards.animate({
                scrollLeft: tabCards.scrollLeft() + tabWidth + 16
            }, 500, "swing");

        });
        prev.click(function (e) {

            tabCards.animate({
                scrollLeft: tabCards.scrollLeft() - (tabWidth + 16)
            }, 500, "swing");

        });
    },
    // accordion: function () {
    //     var panelHeader = $('.panel__header');

    //     panelHeader.click(function () {
    //         $(this).parent().toggleClass('panel--expanded');

    //     });
    // },
    hasTooltip: function () {
        var el = $('.js-has-tooltip');
        var showTooltip = $('.js-show-tooltip');
        el.each(function () {
            var tooltipText = $(this).data('tooltip');
            var tooltipEl = '<span class="tooltip">' + tooltipText + '</span>';

            console.log(tooltipEl);

            $(this).append('<i class="fr-icon-info js-show-tooltip"></i>');
            $(this).append(tooltipEl);
            showTooltip.on('mouseover', function () {

            });
        });
    },
    selectLink: function () {
        $('.selected').click(function () {

            $(this).parent().toggleClass('select-link--expanded');
        });
    },
    isMobile: function () {
        if (window.innerWidth <= 767) {
            return true;
        } else {
            return false;
        }
    },
    isTablet: function () {
        if (window.innerWidth >= 768 && window.innerWidth <= 991) {
            return true;
        } else {
            return false;
        }
    },
    isDesktop: function () {
        if (window.innerWidth >= 992) {
            return true;
        } else {
            return false;
        }
    }
}

$(function () {

    // var cookie = $.cookie("hide_cookies_message");
    // if (!cookie) {
    //     $("#cookie").fadeIn();
    // };

    // $("#cookie #close-cookie-bar").on("click", function () {
    //     $.get("/cookie/hide_cookie", function () {
    //         $("#cookie").remove();
    //     });
    // });


    // $.ajax({
    //     url: '/carrello/get_num_quote',
    //     data: '',
    //     dataType: 'json',
    //     success: function (data) {
    //         if (data) {
    //             $('.cart_sum').addClass("checkout-notification-items");
    //             $('.cart_sum').html(data);
    //         }


    //     }
    // });


    $("#offers-list").on("click", "#next-offers", function () {
        if (jQuery(this).hasClass("loading")) return !1;
        url = jQuery(this).attr("href");
        jQuery(this).addClass("loading");
        jQuery.ajax({
            url: url
        }).done(function (html) {
            var content = jQuery(html).find("#offers-list").html();
            jQuery("#offers-list #next-offers").remove();
            jQuery("#offers-list").append(content);
        });
        return !1
    });


    app.init();

    $('a[href*="#"]').on('click', function (e) {
        e.preventDefault()

        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top,
        },
            300,
            'swing'
        )
    })

    $('#close-cookie-bar').click(function () {
        $('.cookie-bar').css('display', 'none');
    });


});

// headerFixedOnScroll();
function headerFixedOnScroll() {
    let banner = document.getElementById('spotlink');
    let distance = banner.offsetHeight;
    let header = document.querySelector('.header');
    let main = document.getElementsByTagName('main');


    if (app.isMobile()) {
        window.addEventListener('scroll', function () {


            if (window.pageYOffset > distance) {
                header.style.position = 'fixed';
                header.style.top = 0;
                main[0].style.marginTop = header.offsetHeight + 'px';
            } else {
                header.style.position = 'static';
                main[0].style.marginTop = '0';

            }

        })

    }

}
